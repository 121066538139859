import React from "react";
import { render } from "react-dom";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { configureStore, getDefaultMiddleware } from "redux-starter-kit";
import { Provider } from "react-redux";
import rootReducer from "./features";
import wsMiddleware from "./features/websocket/middleware";
import { ToastContainer, toast } from "react-toastify";
import WebsocketConnection from "./components/WebsocketConnection";
import User from "./components/User";
import TopNav from "./components/TopNav";
import BottomBar from "./components/BottomBar";
import Home from "./components/Home";
import TwitchChat from "./components/Twitch/Chat";
import About from "./components/About";
import Library from "./components/Library";
import Admin from "./components/Admin";
import Radio from "./components/Radio";
import * as serviceWorker from "./serviceWorker";
import "./index.css";
import "react-toastify/dist/ReactToastify.min.css";
import "./themes/bootstrap/darkly.min.css";

const store = configureStore({
  reducer: rootReducer,
  middleware: [...getDefaultMiddleware(), wsMiddleware]
});

render(
  <Provider store={store}>
    <Router>
      <ToastContainer
        pauseOnFocusLoss={false}
        position={toast.POSITION.TOP_LEFT}
      />
      <User>
        <WebsocketConnection>
          <TopNav />
          <div className="app">
            <Switch>
              <Route path="/admin" component={Admin} />
              <Route path="/library" component={Library} />
              <Route path="/about" component={About} />
              <Route path="/chat" component={TwitchChat} />
              <Route path="/radio" component={Radio} />
              <Route path="/watch" component={Home} />
              <Route path="/" component={Home} />
            </Switch>
          </div>
          <BottomBar />
        </WebsocketConnection>
      </User>
    </Router>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
