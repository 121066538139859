import React from "react";
import { connect } from "react-redux";
import Navbar from "react-bootstrap/Navbar";
import Badge from "react-bootstrap/Badge";

import VideocamOffIcon from "@material-ui/icons/VideocamOff";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import PauseCircleOutlineIcon from "@material-ui/icons/PauseCircleOutline";

// const showStatusColorMap = {
//   OFFLINE: "dark",
//   RUNNING: "success",
//   PAUSED: "info",
//   STARTED: "success",
//   RESUMED: "success"
// };

const showStatusIconMap = {
  OFFLINE: <VideocamOffIcon />,
  RUNNING: <PlayCircleOutlineIcon />,
  PAUSED: <PauseCircleOutlineIcon />,
  STARTED: <PlayCircleOutlineIcon />,
  RESUMED: <PlayCircleOutlineIcon />,
};

function BottomBar(props) {
  return (
    <>
      <Navbar
        collapseOnSelect
        expand="md"
        //bg={showStatusColorMap[props.showStatus]}
        bg="dark"
        variant="light"
        fixed="bottom"
        style={{ height: "40px" }}
      >
        <Navbar.Collapse>
          <Navbar.Text>
            <Badge variant={props.streamIsLive ? "danger" : "light"}>
              {props.streamIsLive ? "LIVE" : "OFFLINE"}
            </Badge>{" "}
            {showStatusIconMap[props.showStatus]}{" "}
            {props.showStatus !== "PAUSED" ? (
              <>
                Now Showing:{" "}
                {props.currentVideo ? (
                  <strong>{props.currentVideo.title}</strong>
                ) : (
                  <em>Nothing</em>
                )}
              </>
            ) : (
              <em>Playback is Paused</em>
            )}
          </Navbar.Text>
        </Navbar.Collapse>
        <Navbar.Collapse className="justify-content-end">
          <Navbar.Text>
            <small>
              {props.websocket.connected ? (
                <span
                  className={
                    props.websocket.latency === "?" ||
                    props.websocket.latency < 100
                      ? "text-success"
                      : props.websocket.latency < 500
                      ? "text-warning"
                      : "text-danger"
                  }
                >
                  WSS Latency: <strong>{props.websocket.latency}ms</strong>
                </span>
              ) : (
                <span>WSS Not Connected</span>
              )}
            </small>
            {props.obsStats && (
              <small>
                {" | "}
                <strong>
                  {props.obsStats.cpuUsage && (
                    <span>CPU: {props.obsStats.cpuUsage.toFixed(2)}%, </span>
                  )}
                  {props.obsStats.activeFps && (
                    <span>{props.obsStats.activeFps.toFixed(2)} fps</span>
                  )}
                </strong>
              </small>
            )}
          </Navbar.Text>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
}

const mapStateToProps = (state) => ({
  websocket: state.websocket,
  obsStats: state.stream.obsStats,
  showStatus: state.stream.broadcast.showStatus,
  currentVideo: state.stream.broadcast.currentVideo,
  streamIsLive: state.stream.isLive,
  currentSong: state.spotify.currentSong,
});

export default connect(mapStateToProps, null)(BottomBar);
