import React, { useEffect } from "react";
import { connect } from "react-redux";
import { connect as wsConnect } from "../features/websocket/websocketSlice";
import PacmanLoader from "react-spinners/PacmanLoader";
import { css } from "@emotion/core";

const loaderCss = css`
  position: absolute;
  margin: 0 auto;
  top: 25%;
  left: 25%;
  z-index: 9999;
`;

function WebsocketConnection(props) {
  useEffect(() => {
    if (!props.websocket.connected) {
      props.dispatch(wsConnect());
    }
  });

  return (
    <>
      <PacmanLoader
        css={loaderCss}
        loading={props.websocket.request.loading}
        size={150}
        color={"#ffee00"}
      />
      {props.children}
    </>
  );
}

const mapStateToProps = state => ({
  websocket: state.websocket
});

export default connect(mapStateToProps, null)(WebsocketConnection);
