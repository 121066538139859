import React, { Component } from "react";
// bootstrap components
import Container from "react-bootstrap/Container";
import ResponsiveEmbed from "react-bootstrap/ResponsiveEmbed";
// native components
import Embed from "./Twitch/Embed";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentChannel: "fgfm"
    };
  }

  changeChannel(newChannel) {
    this.setState({ currentChannel: newChannel });
  }

  render() {
    return (
      <Container fluid>
        <ResponsiveEmbed aspectRatio="21by9">
          <Embed channel={this.state.currentChannel} theme="dark" />
        </ResponsiveEmbed>
      </Container>
    );
  }
}

export default Home;
