import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
// bootstrap components
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
// react-router-bootstrap
import { LinkContainer } from "react-router-bootstrap";
// material-ui icons
import LiveTvIcon from "@material-ui/icons/LiveTv";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import QueuePlayNextIcon from "@material-ui/icons/QueuePlayNext";
// native components
import PageModal from "./PageModal";
import Library from "./Library";
import About from "./About";

const UserMenuToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    href="#userProfileDropdown"
    ref={ref}
    onClick={e => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </a>
));

function TopNav(props) {
  const [currentPage, setCurrentPage] = useState(null);
  const [showPageModal, setShowPageModal] = useState(false);

  const handleLogOut = () => {
    // Remove cookies via API route
    window.location = process.env.REACT_APP_FGFM_API_URL + "/auth/logout";
  };

  // @TODO Move this to its own component
  let userDisplay = props.user.isLoggedIn ? (
    <Navbar.Text>
      <Dropdown>
        <Dropdown.Toggle as={UserMenuToggle} id="userProfileDropdown">
          <Image
            src={props.user.profile.twitch.profile_image_url}
            roundedCircle
            className="bg-light ml-3"
            alt={"Logged in as " + props.user.profile.displayName}
            width={36}
            height={36}
          />
        </Dropdown.Toggle>
        <Dropdown.Menu alignRight>
          <Dropdown.Item href="#" onClick={handleLogOut}>
            Log Out
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </Navbar.Text>
  ) : (
    <a href={process.env.REACT_APP_TWITCH_LOGIN_URL}>Log In with Twitch</a>
  );

  const showPage = page => {
    setCurrentPage(page);
    setShowPageModal(true);
  };

  return (
    <>
      <PageModal
        show={showPageModal}
        page={currentPage}
        onHide={() => setShowPageModal(false)}
      />
      <Navbar
        collapseOnSelect
        expand="md"
        bg="dark"
        variant="dark"
        className="mb-2"
        style={{ height: "64px" }}
      >
        <LinkContainer to="/" exact>
          <Navbar.Brand>
            <img
              alt=""
              src="/jammin_ham_02_cropped_112.png"
              width="48"
              height="48"
              className="d-inline-block align-middle"
            />
            {" ForeverGrind.fm "}
          </Navbar.Brand>
        </LinkContainer>
        <Navbar.Toggle />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto" activeKey={props.location.pathname}>
            <LinkContainer to="/" exact>
              <Nav.Link eventKey="/">
                <LiveTvIcon /> Watch Stream
              </Nav.Link>
            </LinkContainer>
            {/* <LinkContainer to="/library"> */}
            <Nav.Link eventKey="/library" href="/library" target="_blank">
              <LibraryBooksIcon /> Browse Library
            </Nav.Link>
            {/* </LinkContainer> */}
            <Nav.Link
              eventKey="/about"
              onClick={() =>
                showPage({
                  title: "About ForeverGrind.fm",
                  component: <About />
                })
              }
            >
              <MenuBookIcon /> About
            </Nav.Link>
            {props.user.isLoggedIn &&
              !props.user.banned &&
              props.user.profile.isAdmin && (
                <LinkContainer to="/admin">
                  <Nav.Link eventKey="/admin">
                    <VpnKeyIcon /> Admin
                  </Nav.Link>
                </LinkContainer>
              )}
          </Nav>
        </Navbar.Collapse>
        <Navbar.Collapse className="justify-content-end">
          {props.user.isLoggedIn &&
            !props.user.banned &&
            props.websocket.connected && (
              <Button
                variant="primary"
                onClick={() =>
                  showPage({
                    title: "Request Videos",
                    component: <Library videosPerPage={10} />
                  })
                }
              >
                <QueuePlayNextIcon /> Request Videos
              </Button>
            )}
          {userDisplay}
        </Navbar.Collapse>
      </Navbar>
    </>
  );
}

const mapStateToProps = state => ({
  user: state.user,
  websocket: state.websocket
});

export default connect(mapStateToProps, null)(withRouter(TopNav));
