import React, { Component } from "react";
import { connect } from "react-redux";
import {
  request as wsRequest,
  broadcast as wsBroadcast
} from "../features/websocket/websocketSlice";
// bootstrap components
import Container from "react-bootstrap/Container";
import Alert from "react-bootstrap/Alert";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
// material-ui icons
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import PauseCircleOutlineIcon from "@material-ui/icons/PauseCircleOutline";
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
import DeleteSweepIcon from "@material-ui/icons/DeleteSweep";
import PausePresentationIcon from "@material-ui/icons/PausePresentation";
import ClearAllIcon from "@material-ui/icons/ClearAll";
import QueueIcon from "@material-ui/icons/Queue";
import VideoLabelIcon from "@material-ui/icons/VideoLabel";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import DirectionsRunIcon from "@material-ui/icons/DirectionsRun";
import SkipPreviousIcon from "@material-ui/icons/SkipPrevious";
import SkipNextIcon from "@material-ui/icons/SkipNext";
import VolumeOffIcon from "@material-ui/icons/VolumeOff";
import VolumeUpIcon from "@material-ui/icons/VolumeUp";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import TrendingDownIcon from "@material-ui/icons/TrendingDown";
import MusicNoteIcon from "@material-ui/icons/MusicNote";
import QueueMusicIcon from "@material-ui/icons/QueueMusic";
import AssignmentIcon from "@material-ui/icons/Assignment";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import SportsEsportsIcon from "@material-ui/icons/SportsEsports";
import RefreshIcon from "@material-ui/icons/Refresh";
import PowerIcon from "@material-ui/icons/Power";
import ReplayIcon from "@material-ui/icons/Replay";
import HotelIcon from "@material-ui/icons/Hotel";
// native components
import EventFeed from "./Admin/EventFeed";

const streamButtonGroups = [
  {
    name: "showControls",
    buttons: [
      {
        href: "#stream/START_THE_SHOW",
        label: "Start Show",
        icon: <PlayCircleOutlineIcon />
      },
      {
        href: "#stream/PAUSE_THE_SHOW",
        label: "Pause Show",
        icon: <PauseCircleOutlineIcon />
      },
      {
        href: "#stream/RESUME_THE_SHOW",
        label: "Resume Show",
        icon: <PlayCircleFilledIcon />
      },
      {
        href: "#stream/TEARDOWN",
        label: "Teardown",
        icon: <DeleteSweepIcon />
      }
    ]
  },
  {
    name: "queueControls",
    buttons: [
      {
        href: "#stream/SKIP_CURRENT_VIDEO",
        label: "Skip Current",
        icon: <SkipNextIcon />
      },
      {
        href: "#stream/SKIP_AND_PAUSE",
        label: "Skip & Pause",
        icon: <PausePresentationIcon />
      },
      {
        href: "#stream/CLEAR_THE_QUEUE",
        label: "Clear Queue",
        icon: <ClearAllIcon />
      }
    ]
  },
  {
    name: "infoControls",
    buttons: [
      {
        href: "#stream/GET_VIDEO_QUEUE",
        label: "Get Queue",
        icon: <QueueIcon />
      },
      {
        href: "#stream/GET_CURRENT_VIDEO",
        label: "Get Current",
        icon: <VideoLabelIcon />
      },
      {
        href: "#stream/GET_NEXT_VIDEO",
        label: "Get Next",
        icon: <NavigateNextIcon />
      }
    ]
  },
  {
    name: "stateControls",
    buttons: [
      {
        href: "#stream/GET_STATE",
        label: "Get State",
        icon: <AssignmentIcon />
      },
      {
        href: "#stream/REFRESH_VIDEOS",
        label: "Refresh Videos",
        icon: <RefreshIcon />
      },
      {
        href: "#stream/CONNECT_TO_OBS",
        label: "Reconnect to OBS",
        icon: <PowerIcon />
      },
      {
        href: "#stream/RESTART_SERVICE",
        label: "RESTART SERVICE",
        icon: <ReplayIcon />,
        variant: "outline-danger"
      }
    ]
  }
];
const spotifyButtonGroups = [
  {
    name: "playlistControls",
    buttons: [
      {
        href: "#spotify/PLAY_FUNK",
        label: "FUNK",
        icon: <DirectionsRunIcon />,
        variant: "outline-success"
      },
      {
        href: "#spotify/PLAY_VGM",
        label: "VGM",
        icon: <SportsEsportsIcon />,
        variant: "outline-success"
      },
      {
        href: "#spotify/GET_COMFY",
        label: "Comfy",
        icon: <HotelIcon />,
        variant: "outline-success"
      }
    ]
  },
  {
    name: "playbackControls",
    buttons: [
      {
        href: "#spotify/RESUME_PLAYBACK",
        label: "Resume",
        icon: <PlayCircleFilledIcon />,
        variant: "outline-success"
      },
      {
        href: "#spotify/PAUSE_PLAYBACK",
        label: "Pause",
        icon: <PauseCircleOutlineIcon />,
        variant: "outline-success"
      },
      {
        href: "#spotify/PLAY_LAST_SONG",
        label: "Previous",
        icon: <SkipPreviousIcon />,
        variant: "outline-success"
      },
      {
        href: "#spotify/SKIP_CURRENT_SONG",
        label: "Skip",
        icon: <SkipNextIcon />,
        variant: "outline-success"
      }
    ]
  },
  {
    name: "volumeControls",
    buttons: [
      {
        href: "#spotify/FADE_OUT",
        label: "Fade Out",
        icon: <TrendingDownIcon />,
        variant: "outline-success"
      },
      {
        href: "#spotify/FADE_IN",
        label: "Fade In",
        icon: <TrendingUpIcon />,
        variant: "outline-success"
      },
      {
        href: "#spotify/MUTE",
        label: "Mute",
        icon: <VolumeOffIcon />,
        variant: "outline-success"
      },
      {
        href: "#spotify/UNMUTE",
        label: "Unmute",
        icon: <VolumeUpIcon />,
        variant: "outline-success"
      }
    ]
  },
  {
    name: "infoControls",
    buttons: [
      {
        href: "#spotify/GET_CURRENT_SONG",
        label: "Current Song",
        icon: <MusicNoteIcon />,
        variant: "outline-success"
      },
      {
        href: "#spotify/GET_CURRENT_PLAYLIST",
        label: "Current Playlist",
        icon: <QueueMusicIcon />,
        variant: "outline-success"
      },
      {
        href: "#spotify/GET_RECENTLY_PLAYED",
        label: "Recently Played",
        icon: <QueueMusicIcon />,
        variant: "outline-success"
      }
    ]
  },
  {
    name: "stateControls",
    buttons: [
      {
        href: "#spotify/GET_STATE",
        label: "Current State",
        icon: <AssignmentIcon />,
        variant: "outline-success"
      },
      {
        href: "#spotify/GET_AUTH_URL",
        label: "Re-Auth",
        icon: <VpnKeyIcon />,
        variant: "outline-success"
      },
      {
        href: "#spotify/RESTART_SERVICE",
        label: "RESTART SERVICE",
        icon: <ReplayIcon />,
        variant: "outline-danger"
      }
    ]
  }
];
const twitchButtonGroups = [
  {
    name: "serviceControls",
    buttons: [
      {
        href: "#twitch/TIMEOUT_TEST",
        label: "TEST TIMEOUT",
        icon: <ReplayIcon />,
        variant: "outline-warning"
      },
      {
        href: "#twitch/RESTART_SERVICE",
        label: "RESTART SERVICE",
        icon: <ReplayIcon />,
        variant: "outline-danger"
      }
    ]
  }
];

const buttonToolbars = [
  {
    label: "Stream",
    buttonGroups: streamButtonGroups
  },
  {
    label: "Spotify",
    buttonGroups: spotifyButtonGroups
  },
  {
    label: "Twitch",
    buttonGroups: twitchButtonGroups
  }
];

const serviceCommandMap = [
  {
    name: "stream",
    label: "Stream",
    requests: [
      {
        name: "TOGGLE_SCENE_ITEM_VISIBILITY",
        args: JSON.stringify({ sceneItem: "" })
      },
      {
        name: "SWITCH_TO_SCENE",
        args: JSON.stringify({ newScene: "" })
      },
      {
        name: "MUTE_SOURCE",
        args: JSON.stringify({ source: "sourceName" })
      },
      {
        name: "UNMUTE_SOURCE",
        args: JSON.stringify({ source: "sourceName" })
      },
      {
        name: "TOGGLE_MUTE",
        args: JSON.stringify({ source: "sourceName" })
      },
      {
        name: "CHANGE_SOURCE_VOLUME",
        args: JSON.stringify({ source: "sourceName", volume: 0.5 })
      }
    ]
  },
  {
    name: "spotify",
    label: "Spotify",
    requests: [
      {
        name: "PLAY",
        args: JSON.stringify({ url: "spotifyUri" })
      },
      {
        name: "SET_VOLUME",
        args: JSON.stringify({ volume: 50 })
      },
      {
        name: "SET_SHUFFLE_STATE",
        args: JSON.stringify({ state: true })
      },
      {
        name: "SET_REPEAT_STATE",
        args: JSON.stringify({ state: "context" })
      }
    ]
  },
  {
    name: "twitch",
    label: "Twitch",
    requests: [
      {
        name: "SAY",
        args: JSON.stringify({ message: "message here", channel: null })
      },
      {
        name: "PYRAMID",
        args: JSON.stringify({ message: "DutchGlow", channel: null, width: 5 })
      },
      {
        name: "TOGGLE_TIMER",
        args: JSON.stringify({ name: "timerName" })
      }
    ]
  }
];

class Admin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      serviceTarget: serviceCommandMap[0].name,
      requestToSend: serviceCommandMap[0].requests[0].name,
      requestArguments: serviceCommandMap[0].requests[0].args,
      broadcastToSend: "",
      showDialog: false,
      dialogTitle: "",
      dialogBody: "",
      showStreamPreview: false
    };
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.websocket.request.loading !==
      this.props.websocket.request.loading
    ) {
      if (
        typeof this.props.websocket.request.results === "object" &&
        this.props.websocket.request.results !== null &&
        this.props.websocket.request.request.url !== "stream/ADD_VIDEO_TO_QUEUE"
      ) {
        this.showDialog(
          <pre className="pre-scrollable">
            {JSON.stringify(this.props.websocket.request.results, null, 2)}
          </pre>,
          this.props.websocket.request.request.url
        );
      }

      if (this.props.websocket.request.request.url === "spotify/GET_AUTH_URL") {
        return window.open(this.props.websocket.request.results, "_blank");
      }
    }
  }

  handleTargetChange(event) {
    let serviceCommands = serviceCommandMap.find(
      x => x.name === event.target.value
    ).requests;

    this.setState({
      serviceTarget: event.target.value,
      requestToSend: serviceCommands[0].name,
      requestArguments: serviceCommands[0].args
    });
  }

  handleRequestChange(event) {
    this.setState({
      requestToSend: event.target.value,
      requestArguments: serviceCommandMap
        .find(x => x.name === this.state.serviceTarget)
        .requests.find(x => x.name === event.target.value).args
    });
  }

  handleArgumentsChange(event) {
    this.setState({ requestArguments: event.target.value });
  }

  handleRequestSubmit(event) {
    event.preventDefault();
    let url = `${this.state.serviceTarget}/${this.state.requestToSend}`;
    let args = this.state.requestArguments
      ? JSON.parse(this.state.requestArguments)
      : {};

    this.props.dispatch(wsRequest({ url, args }));
  }

  handleBroadcastChange(event) {
    this.setState({ broadcastToSend: event.target.value });
  }

  handleBroadcastSubmit(event) {
    event.preventDefault();
    this.props.dispatch(wsBroadcast({ eventName: this.state.broadcastToSend }));
    this.setState({ broadcastToSend: "" });
  }

  handleSimpleActionClick(event) {
    event.preventDefault();
    let target = event.currentTarget;
    target.disabled = true;

    let url = target.hash.replace("#", "");

    this.props.dispatch(wsRequest({ url }));

    target.disabled = false;
  }

  showEventDetails(event) {
    event.preventDefault();
    let messageIdx = parseInt(event.target.hash.replace("#", ""));
    this.showDialog(
      <pre className="pre-scrollable">
        {JSON.stringify(
          this.props.websocket.broadcasts.slice(0).reverse()[messageIdx],
          null,
          2
        )}
      </pre>,
      "Event Details"
    );
  }

  showDialog(body, title) {
    title = title || "Notification";
    this.setState({ showDialog: true, dialogBody: body, dialogTitle: title });
  }

  handleCloseDialog() {
    this.setState({ showDialog: false });
  }

  render() {
    if (!this.props.user.isLoggedIn || !this.props.user.profile.isAdmin) {
      return null;
    }

    return (
      <Container>
        <Modal
          show={this.state.showDialog}
          onHide={this.handleCloseDialog.bind(this)}
        >
          <Modal.Header closeButton>
            <Modal.Title>{this.state.dialogTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{this.state.dialogBody}</Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={this.handleCloseDialog.bind(this)}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        <Row>
          <Col xs={12} sm={12} md={7}>
            {buttonToolbars.map((btnToolbar, idx) => (
              <Alert variant="dark" key={idx}>
                <Alert.Heading>
                  {btnToolbar.label} Service Controls
                </Alert.Heading>
                <ButtonToolbar key={idx} aria-label={btnToolbar.label}>
                  {btnToolbar.buttonGroups.map((group, groupIdx) => (
                    <ButtonGroup
                      aria-label={group.name}
                      className="mr-2 mb-2"
                      key={groupIdx}
                    >
                      {group.buttons.map((btn, btnIdx) => (
                        <Button
                          href={btn.href}
                          onClick={this.handleSimpleActionClick.bind(this)}
                          size="sm"
                          variant={btn.variant || "outline-info"}
                          key={btnIdx}
                        >
                          {btn.icon}
                          <br />
                          {btn.label}
                        </Button>
                      ))}
                    </ButtonGroup>
                  ))}
                </ButtonToolbar>
              </Alert>
            ))}

            <Form onSubmit={this.handleRequestSubmit.bind(this)}>
              <Form.Row>
                <Col>
                  <Form.Control
                    as="select"
                    value={this.state.serviceTarget}
                    onChange={this.handleTargetChange.bind(this)}
                  >
                    {serviceCommandMap.map((commandGroup, idx) => (
                      <option value={commandGroup.name} key={idx}>
                        {commandGroup.label}
                      </option>
                    ))}
                  </Form.Control>
                </Col>
                <Col>
                  <Form.Control
                    as="select"
                    value={this.state.requestToSend}
                    onChange={this.handleRequestChange.bind(this)}
                  >
                    {serviceCommandMap
                      .find(x => x.name === this.state.serviceTarget)
                      .requests.map((r, idx) => (
                        <option value={r.name} key={idx}>
                          {r.name}
                        </option>
                      ))}
                  </Form.Control>
                </Col>
                {/* <Col>
                  <Form.Control
                    type="text"
                    value={this.state.requestToSend}
                    onChange={this.handleRequestChange.bind(this)}
                    placeholder="Custom request name to send..."
                  />
                </Col> */}
              </Form.Row>
              <Form.Row>
                <Col>
                  <Form.Control
                    as="textarea"
                    value={this.state.requestArguments}
                    onChange={this.handleArgumentsChange.bind(this)}
                    placeholder="Arguments (JSON string) to send..."
                    style={{
                      fontFamily:
                        "Consolas,Monaco,Lucida Console,Liberation Mono,DejaVu Sans Mono,Bitstream Vera Sans Mono,Courier New, monospace"
                    }}
                  />
                </Col>
              </Form.Row>
              <Button variant="primary" type="submit">
                Submit
              </Button>
            </Form>
            <hr />
            <Form onSubmit={this.handleBroadcastSubmit.bind(this)}>
              <Form.Row>
                <Col>
                  <Form.Control
                    type="text"
                    value={this.state.broadcastToSend}
                    onChange={this.handleBroadcastChange.bind(this)}
                    placeholder="Event name to broadcast..."
                  />
                </Col>
                <Col>
                  <Button variant="primary" type="submit">
                    Send
                  </Button>
                </Col>
              </Form.Row>
            </Form>
          </Col>
          <Col xs={12} sm={12} md={5}>
            <EventFeed
              events={this.props.websocket.broadcasts}
              reverse={true}
              currentEvent={this.props.websocket.lastBroadcast}
              handlers={{
                showEventDetails: this.showEventDetails.bind(this)
              }}
            />
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  websocket: state.websocket,
  currentVideo: state.stream.currentVideo,
  obsStats: state.stream.obsStats,
  user: state.user
});

export default connect(mapStateToProps, null)(Admin);
