import { createSlice } from "redux-starter-kit";

const initialState = {
  isPlaying: false,
  currentSong: null,
  currentPlaylist: null
};

const spotifySlice = createSlice({
  name: "spotify",
  initialState,
  reducers: {
    changeSong(state, action) {
      state.currentSong = action.payload;
    },
    changePlaylist(state, action) {
      state.currentPlaylist = action.payload;
    },
    setSpotifyState(state, action) {
      if (
        Object.entries(action.payload).length === 0 &&
        action.payload.constructor === Object
      ) {
        state = initialState;
      } else {
        state.isPlaying = action.payload.is_playing;
        state.currentSong = {
          title: action.payload.item.name,
          by: action.payload.item.artists.map(x => x.name).join(", "),
          url: action.payload.item.external_urls.spotify
        };
      }
    }
  }
});

export const {
  changeSong,
  changePlaylist,
  setSpotifyState
} = spotifySlice.actions;

export default spotifySlice.reducer;
