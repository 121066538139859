import React from "react";
import uuidv1 from "uuid/v1";
const EMBED_URL = "https://embed.twitch.tv/embed/v1.js";

class Embed extends React.Component {
  constructor(props) {
    super(props);
    this.targetId = uuidv1();
    this.embed = null;
    this.player = null;
  }

  componentDidMount() {
    const script = document.createElement("script");
    script.setAttribute("src", EMBED_URL);
    script.addEventListener("load", () => {
      this.embed = new window.Twitch.Embed(this.targetId, { ...this.props });
      this.embed.addEventListener(window.Twitch.Embed.VIDEO_READY, () => {
        this.player = this.embed.getPlayer();
      });
    });
    document.body.appendChild(script);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.channel !== this.props.channel) {
      this.player.setChannel(this.props.channel);
    }
  }

  componentWillUnmount() {
    // @TODO handle this? maybe this is where we can hook in to provide the "floating" player
  }

  render() {
    return <div id={this.targetId}></div>;
  }
}

export default Embed;
