import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import { loggedIn, loggedOut } from "../features/user/userSlice";

class User extends Component {
  componentDidMount() {
    this.fetchUserData();
  }

  fetchUserData() {
    // Call the API to retrieve info about the currently logged-in user
    axios
      .get(`${process.env.REACT_APP_FGFM_API_URL}/api/me`, {
        withCredentials: true
      })
      .then(
        res => {
          this.props.dispatch(loggedIn(res.data));
        },
        err => {
          this.props.dispatch(loggedOut());
          console.error(err);
        }
      );
  }

  render() {
    return <div>{this.props.children}</div>;
  }
}

export default connect()(User);
