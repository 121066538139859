import React, { useState } from "react";
// bootstrap components
import Container from "react-bootstrap/Container";

export default function TwitchChat(props) {
  // eslint-disable-next-line no-unused-vars
  const [currentChannel, setChannel] = useState(props.channel || "greenham");

  return (
    <Container>
      <div className="text-center">
        <iframe
          title="Twitch Chat"
          frameborder="0"
          scrolling="no"
          id="chat_embed"
          src={`https://www.twitch.tv/embed/${currentChannel}/chat`}
          height="720"
          width="500"
        ></iframe>
      </div>
    </Container>
  );
}
