import { createSlice, createAction } from "redux-starter-kit";
import uuidv1 from "uuid/v1";

const sliceName = "websocket";

const websocketSlice = createSlice({
  name: sliceName,
  initialState: {
    connected: false,
    lastError: null,
    latency: "?",
    request: {
      loading: false,
      error: null,
      results: null
    },
    lastRequestFinished: null,
    broadcasts: [],
    lastBroadcast: null
  },
  reducers: {
    connected(state) {
      state.connected = true;
    },
    disconnected(state, action) {
      state.connected = false;
      state.lastError = action.payload;
    },
    error(state, action) {
      state.lastError = action.payload;
    },
    pong(state, action) {
      state.latency = action.payload;
    },
    requestStarted(state, action) {
      state.request.loading = true;
      state.request.request = action.payload;
      state.request.id = uuidv1();
      state.request.error = null;
      state.request.results = null;
    },
    requestError(state, action) {
      state.request.loading = false;
      state.request.error = action.payload;
      state.lastRequestFinished = state.request.id;
    },
    requestSuccess(state, action) {
      state.request.loading = false;
      state.request.error = null;
      state.request.results = action.payload;
      state.lastRequestFinished = state.request.id;
    },
    requestTimedOut(state, action) {
      state.request.loading = false;
      state.request.error = action.payload;
      state.request.results = null;
      state.lastRequestFinished = state.request.id;
    },
    receiveBroadcast(state, action) {
      state.broadcasts.push(action.payload);
      state.lastBroadcast = action.payload;
    }
  }
});

export const connect = createAction(`${sliceName}/connect`);
export const disconnect = createAction(`${sliceName}/disconnect`);
export const request = createAction(`${sliceName}/request`);
export const broadcast = createAction(`${sliceName}/broadcast`);

export const {
  connected,
  disconnected,
  error,
  pong,
  requestStarted,
  requestError,
  requestSuccess,
  requestTimedOut,
  receiveBroadcast
} = websocketSlice.actions;

export default websocketSlice.reducer;
