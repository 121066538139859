import FgfmWebsocketClient from "fgfm-websocket-client";
import * as wsActions from "./websocketSlice";
import {
  videoShown,
  obsHeartbeat,
  setBroadcastState,
  setShowStatus,
} from "../stream/streamSlice";
import { setSpotifyState } from "../spotify/spotifySlice";
import { toast } from "react-toastify";

const socketMiddleware = () => {
  let socket = new FgfmWebsocketClient({
    url: process.env.REACT_APP_FGFM_WEBSOCKET_SERVER_URL,
    serviceName: process.env.REACT_APP_FGFM_SERVICE_NAME,
    timeoutAfterSeconds: 15,
  });

  const onOpen = (store) => {
    store.dispatch(wsActions.connected());

    socket.req("stream/GET_STATE", (res) => {
      if (res.status === "ok") {
        store.dispatch(setBroadcastState(res.data));
      }
    });

    socket.req("spotify/GET_STATE", (res) => {
      if (res.status === "ok") {
        store.dispatch(setSpotifyState(res.data));
      }
    });
  };

  const onError = (store, error) => {
    console.error("websocket error", error);
    store.dispatch(wsActions.error(error));
  };

  const onClose = (store, reason) => {
    store.dispatch(wsActions.disconnected(reason));
  };

  const onPong = (store, latency) => {
    store.dispatch(wsActions.pong(latency));
  };

  const onBroadcast = (store, data) => {
    if (data.eventName !== "OBS_HEARTBEAT") {
      store.dispatch(wsActions.receiveBroadcast(data));
    }

    switch (data.eventName) {
      case "VIDEO_SHOWN":
        store.dispatch(videoShown(data.data));
        break;
      case "SHOWING_ROOM_GRIND":
        store.dispatch(videoShown({ title: "Room Grind" }));
        break;
      case "OBS_HEARTBEAT":
        store.dispatch(obsHeartbeat(data.data));
        break;
      case "SHOW_STARTED":
      case "SHOW_PAUSED":
      case "SHOW_RESUMED":
        store.dispatch(setShowStatus(data.eventName.split("_")[1]));
        break;
      default:
        break;
    }
  };

  const onRequestResults = (store, result) => {
    if (result.status === "ok") {
      store.dispatch(wsActions.requestSuccess(result.data));
      toast("Success!", { type: toast.TYPE.SUCCESS });
    } else {
      if (result.timedOut) {
        store.dispatch(wsActions.requestTimedOut(result.error));
      } else {
        store.dispatch(wsActions.requestError(result.error));
      }
      toast(result.error, { type: toast.TYPE.ERROR });
    }
  };

  // the middleware part of this function
  return (store) => (next) => (action) => {
    switch (action.type) {
      case "websocket/connect":
        if (socket !== null) {
          socket.close();
        }

        // connect to the remote host
        socket.connect();

        // websocket event handlers
        socket.on("connect", () => {
          onOpen(store);
        });

        socket.on("error", (error) => {
          onError(store, error);
        });

        socket.on("disconnect", (reason) => {
          onClose(store, reason);
        });

        socket.on("broadcast", (data) => {
          onBroadcast(store, data);
        });

        socket.on("pong", (latency) => {
          onPong(store, latency);
        });

        break;
      case "websocket/disconnect":
        if (socket !== null) {
          socket.close();
        }
        socket = null;
        break;
      case "websocket/broadcast":
        socket.broadcast(action.payload.eventName, action.payload.data);
        break;
      case "websocket/request":
        store.dispatch(wsActions.requestStarted(action.payload));
        socket.req(action.payload.url, action.payload.args, (res) => {
          onRequestResults(store, res);
        });
        break;
      default:
        return next(action);
    }
  };
};

export default socketMiddleware();
