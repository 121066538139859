import React from "react";
import { Link } from "react-router-dom";
// bootstrap components
import Container from "react-bootstrap/Container";

export default function About() {
  return (
    <Container>
      <p>
        ForeverGrind.fm is a continuous stream of speedrunning content plus
        handcrafted playlists of chill tunes and hot mixes for your enjoyment.
        You can <Link to="/">view the stream here at ForeverGrind.fm</Link> or
        on Twitch at <a href="https://twitch.tv/fgfm">https://twitch.tv/fgfm</a>
        .
      </p>

      <p>
        This stream serves as a 24/7 video-on-demand service for speedrunning
        content, primarily The Legend of Zelda: A Link to the Past. Viewers can
        request specific videos to add to the queue{" "}
        <Link to="/library">from the Library</Link> or by using the $vr command
        in Twitch chat.
      </p>

      <p>
        If nothing specific is queued, videos are selected at random. Content
        includes: Theory TAS segments + full videos, gold segments, personal
        bests, individual room videos (which are looped for a few minutes), and
        the room grind used to produce the Theory TASes.
      </p>

      <p>
        Apart from the audiovisual content, there are several minigames
        available through Twitch chat to earn virtual points. Points are reset
        at the end of each month and the viewer with the most will receive a
        free gift sub! Trivia questions are asked every 5 minutes (pulled from a
        database of over 200,000 Jeopardy! questions). Other minigames include
        !slots, !gamble, !duel, !ffa, !raid, and !heist.
      </p>

      <p>
        If you'd like to support the project and its continued development, the
        best way to do so is with a direct donation, which can be done here:{" "}
        <a href="https://ko-fi.com/forevergrindfm">
          https://ko-fi.com/forevergrindfm
        </a>
        . But even if you're just lurking, you're helping the channel, so thank
        you!
      </p>

      <p>
        More info about the genesis and history of FGfm can be found here:{" "}
        <a href="https://pastebin.com/FBxPQ4s4">
          https://pastebin.com/FBxPQ4s4
        </a>
      </p>
    </Container>
  );
}
