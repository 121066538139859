import { combineReducers } from "redux";
import websocketReducer from "./websocket/websocketSlice";
import spotifyReducer from "./spotify/spotifySlice";
import streamReducer from "./stream/streamSlice";
import userReducer from "./user/userSlice";

export default combineReducers({
  websocket: websocketReducer,
  spotify: spotifyReducer,
  stream: streamReducer,
  user: userReducer
});
