import { createSlice } from "redux-starter-kit";

const userSlice = createSlice({
  name: "user",
  initialState: {
    isLoggedIn: false,
    profile: {}
  },
  reducers: {
    loggedIn(state, action) {
      state.isLoggedIn = true;
      state.profile = action.payload;
    },
    loggedOut(state, action) {
      state.isLoggedIn = false;
      state.profile = {};
    }
  }
});

export const { loggedIn, loggedOut } = userSlice.actions;

export default userSlice.reducer;
