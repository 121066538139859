import React, { Component } from "react";
import { connect } from "react-redux";
import FgfmApiClient from "fgfm-api-client";
import { request as wsRequest } from "../features/websocket/websocketSlice";
import { secondsToHHMMSS } from "../utils";
// bootstrap components
import Container from "react-bootstrap/Container";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import Alert from "react-bootstrap/Alert";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Badge from "react-bootstrap/Badge";
// other components
import Pagination from "react-js-pagination";
// material ui icons
import QueuePlayNextIcon from "@material-ui/icons/QueuePlayNext";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import SkipPreviousIcon from "@material-ui/icons/SkipPrevious";
import SkipNextIcon from "@material-ui/icons/SkipNext";
import LastPageIcon from "@material-ui/icons/LastPage";
import SearchIcon from "@material-ui/icons/Search";

const filterMap = {
  gameSlug: {
    all: "All Games",
    alttp: "Zelda 3",
    sm: "Super Metroid",
    ff4: "Final Fantasy IV",
    ff6: "Final Fantasy VI",
    smw: "Super Mario World"
  },
  type: {
    all: "All Video Types",
    pb: "Personal Bests",
    "gold-segment": "Gold Segments",
    tutorial: "Tutorials",
    ttas: "Theory TASes",
    comparison: "Comparisons",
    room: "Individual Rooms",
    meme: "Memes"
  }
};

class Library extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: {
        gameSlug: "all",
        type: "all",
        includeInLibrary: true
      },
      allVideos: [],
      filteredVideos: [],
      searchedFilteredVideos: [],
      currentPageVideos: [],
      activePage: 1,
      searchQuery: ""
    };

    this.videosPerPage = props.videosPerPage || 10;

    this.fgfmApi = new FgfmApiClient(process.env.REACT_APP_FGFM_API_URL);
  }

  componentWillMount() {
    this.fgfmApi.getVideos().then(
      (videos) => {
        let filteredVideos = this.getFilteredVideos(videos, this.state.filters);
        this.setState({
          allVideos: videos,
          filteredVideos,
          currentPageVideos: this.getCurrentPageVideos(
            filteredVideos,
            this.state.activePage
          )
        });
      },
      (err) => {
        console.error("error while fetching videos", err);
      }
    );
  }

  getFilteredVideos(videos, filters) {
    return videos.filter((x) => {
      let include = true;
      for (let [key, value] of Object.entries(filters)) {
        if (value === "all") continue;
        include = include && x[key] === value;
      }
      return include;
    });
  }

  getCurrentPageVideos(videos, currentPage) {
    let startIndex =
      currentPage > 1 ? (currentPage - 1) * this.videosPerPage : 0;
    return videos.slice(startIndex, startIndex + this.videosPerPage);
  }

  getSearchedFilteredVideos(videos, searchQuery) {
    if (!searchQuery) return [];

    return videos.filter((x) => {
      let regex = new RegExp(searchQuery.split(" ").join("|"), "i");
      return (
        regex.test(x.type) ||
        regex.test(x.shortId) ||
        regex.test(x.gameSlug) ||
        regex.test(x.title) ||
        x.tags.findIndex((t) => regex.test(t)) !== -1
      );
    });
  }

  handleVideoRequestClick(e) {
    e.preventDefault();

    if (
      this.props.requestLoading ||
      !this.props.user ||
      !this.props.user.isLoggedIn ||
      this.props.user.banned
    ) {
      return;
    }

    let shortId = e.currentTarget.hash.replace("#", "");

    this.props.dispatch(
      wsRequest({
        url: "stream/ADD_VIDEO_TO_QUEUE",
        args: {
          query: shortId,
          requestedBy: this.props.user.profile.displayName,
          userIsAdmin: this.props.user.profile.isAdmin
        }
      })
    );
  }

  handleFilterChange(e) {
    e.preventDefault();
    let filter = e.currentTarget.getAttribute("filter");
    let newValue = e.currentTarget.hash.replace("#", "");
    let newFilters = { ...this.state.filters };
    newFilters[filter] = newValue;
    let filteredVideos = this.getFilteredVideos(
      this.state.allVideos,
      newFilters
    );

    let searchedFilteredVideos =
      this.state.searchQuery.length > 0
        ? this.getSearchedFilteredVideos(filteredVideos, this.state.searchQuery)
        : [];
    let currentPageVideos =
      this.state.searchQuery.length > 0
        ? this.getCurrentPageVideos(searchedFilteredVideos, 1)
        : this.getCurrentPageVideos(filteredVideos, 1);

    this.setState({
      filters: newFilters,
      filteredVideos,
      searchedFilteredVideos,
      activePage: 1,
      currentPageVideos
    });
  }

  handlePageChange(pageNumber) {
    this.setState({
      activePage: pageNumber,
      currentPageVideos: this.getCurrentPageVideos(
        this.state.searchedFilteredVideos.length > 0
          ? this.state.searchedFilteredVideos
          : this.state.filteredVideos,
        pageNumber
      )
    });
  }

  handleSearchChange(e) {
    let searchQuery = e.currentTarget.value;

    // Sanitize user input
    searchQuery = searchQuery
      .split("")
      .filter((x) => /[a-z0-9-\s]/i.test(x))
      .join("");

    this.setState({ searchQuery });

    if (searchQuery.length > 0) {
      let searchedFilteredVideos = this.getSearchedFilteredVideos(
        this.state.filteredVideos,
        searchQuery
      );

      this.setState({
        activePage: 1,
        searchedFilteredVideos,
        currentPageVideos: this.getCurrentPageVideos(searchedFilteredVideos, 1)
      });
    } else {
      this.setState({
        activePage: 1,
        searchedFilteredVideos: [],
        currentPageVideos: this.getCurrentPageVideos(
          this.state.filteredVideos,
          1
        )
      });
    }
  }

  render() {
    return (
      <Container>
        <ButtonToolbar className="float-left">
          {Object.entries(filterMap).map(([key, filters]) => (
            <Dropdown className="mb-2 mr-1" key={key}>
              <Dropdown.Toggle variant="primary" id="dropdown-basic">
                {filters[this.state.filters[key]]}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {Object.entries(filters).map(([filterKey, label]) => (
                  <Dropdown.Item
                    key={filterKey}
                    filter={key}
                    onClick={this.handleFilterChange.bind(this)}
                    href={"#" + filterKey}
                  >
                    {label}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          ))}
        </ButtonToolbar>

        <div className="float-right">
          <Form inline className="mb-2">
            <InputGroup>
              <Form.Control
                type="text"
                placeholder="Search Videos"
                value={this.state.searchQuery}
                onChange={this.handleSearchChange.bind(this)}
              />
              <InputGroup.Append>
                <Button
                  variant="secondary"
                  disabled={this.state.searchQuery.length === 0}
                  onClick={() => {
                    this.setState({ searchQuery: "" });
                    this.handleSearchChange({ currentTarget: { value: "" } });
                  }}
                >
                  {this.state.searchQuery.length === 0 ? (
                    <SearchIcon fontSize="inherit" />
                  ) : (
                    "x"
                  )}
                </Button>
              </InputGroup.Append>
            </InputGroup>
          </Form>
        </div>

        <Table
          responsive
          striped
          bordered
          hover
          variant="dark"
          className={
            this.state.currentPageVideos.length > 0 ? "d-table" : "d-none"
          }
        >
          <thead>
            <tr>
              <th>ID</th>
              <th
                className={
                  this.state.filters.gameSlug === "all"
                    ? "d-table-cell"
                    : "d-none"
                }
              >
                Game
              </th>
              <th
                className={
                  this.state.filters.type === "all" ? "d-table-cell" : "d-none"
                }
              >
                Type
              </th>
              <th>Title</th>
              <th className="d-sm-none d-md-table-cell">Tags</th>
              <th className="d-sm-none d-md-table-cell">Duration</th>
              {this.props.user.isLoggedIn && !this.props.user.banned && (
                <th>Request</th>
              )}
            </tr>
          </thead>
          <tbody>
            {this.state.currentPageVideos.map((x) => (
              <tr key={x._id} title={x.title}>
                <td>{x.shortId}</td>
                <td
                  className={
                    this.state.filters.gameSlug === "all"
                      ? "d-table-cell"
                      : "d-none"
                  }
                >
                  {x.gameSlug}
                </td>
                <td
                  className={
                    this.state.filters.type === "all"
                      ? "d-table-cell"
                      : "d-none"
                  }
                >
                  {x.type}
                </td>
                <td>{x.title}</td>
                <td className="d-sm-none d-md-table-cell">
                  {x.tags.map((t, idx) => (
                    <Badge pill variant="dark" key={x.shortId + "-tag-" + idx}>
                      {t.toUpperCase()}
                    </Badge>
                  ))}
                </td>
                <td className="d-sm-none d-md-table-cell">
                  {secondsToHHMMSS(x.duration)}
                </td>
                {this.props.user.isLoggedIn && !this.props.user.banned && (
                  <td>
                    <Button
                      variant="outline-info"
                      size="sm"
                      disabled={
                        !this.props.websocket.connected ||
                        !this.props.user.isLoggedIn ||
                        this.props.user.banned ||
                        this.props.requestLoading
                      }
                      href={"#" + x.shortId}
                      onClick={this.handleVideoRequestClick.bind(this)}
                    >
                      <QueuePlayNextIcon />
                    </Button>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </Table>

        <Alert
          variant="info"
          className={
            this.state.currentPageVideos.length === 0 ? "d-block" : "d-none"
          }
        >
          No videos found for the current filters and search query.
        </Alert>

        <p className="float-left">
          {this.state.searchQuery.length > 0
            ? this.state.searchedFilteredVideos.length
            : this.state.filteredVideos.length}{" "}
          results{" | "}
          Showing Page {this.state.activePage} of{" "}
          {Math.ceil(
            (this.state.searchedFilteredVideos.length > 0
              ? this.state.searchedFilteredVideos.length
              : this.state.filteredVideos.length) / this.videosPerPage
          )}
        </p>

        <Pagination
          totalItemsCount={
            this.state.searchedFilteredVideos.length > 0
              ? this.state.searchedFilteredVideos.length
              : this.state.filteredVideos.length
          }
          onChange={this.handlePageChange.bind(this)}
          activePage={this.state.activePage}
          itemsCountPerPage={this.videosPerPage}
          pageRangeDisplayed={10}
          innerClass="pagination float-right"
          itemClass="page-item"
          linkClass="page-link"
          firstPageText={<FirstPageIcon fontSize="inherit" />}
          prevPageText={<SkipPreviousIcon fontSize="inherit" />}
          nextPageText={<SkipNextIcon fontSize="inherit" />}
          lastPageText={<LastPageIcon fontSize="inherit" />}
        />
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  websocket: state.websocket,
  currentVideo: state.stream.currentVideo,
  user: state.user,
  requestLoading: state.websocket.request.loading
});

export default connect(mapStateToProps, null)(Library);
