import React from "react";
import Modal from "react-bootstrap/Modal";

export default function PageModal(props) {
  const { show, onHide, page } = props;

  if (!page) {
    return null;
  }

  return (
    <Modal size="lg" show={show} onHide={onHide} aria-labelledby="page-modal">
      <Modal.Header closeButton>
        <Modal.Title id="page-modal">{page.title || ""}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{page.component}</Modal.Body>
    </Modal>
  );
}
