import React from "react";
// bootstrap components
import Container from "react-bootstrap/Container";

export default function Radio(props) {
  return (
    <Container>
      <audio autoplay controls preload="none">
        <source src="http://greenham.dyndns.tv:8000/stream" />
      </audio>
    </Container>
  );
}
