import { createSlice } from "redux-starter-kit";

const streamSlice = createSlice({
  name: "stream",
  initialState: {
    broadcast: {
      showStatus: "OFFLINE",
      videoQueue: [],
      recentlyPlayed: [],
      currentVideo: { title: "Loading..." },
      lastCommercialShownAt: null,
    },
    isLive: false,
    obsStats: {
      cpuUsage: 0,
      activeFps: 0,
    },
  },
  reducers: {
    videoShown(state, action) {
      state.broadcast.currentVideo = action.payload;
    },
    obsHeartbeat(state, action) {
      state.isLive = action.payload.streaming;
      state.obsStats = action.payload.stats;
    },
    setBroadcastState(state, action) {
      state.broadcast = action.payload;
    },
    setShowStatus(state, action) {
      state.broadcast.showStatus = action.payload;
    },
  },
});

export const { videoShown, obsHeartbeat, setBroadcastState, setShowStatus } =
  streamSlice.actions;

export default streamSlice.reducer;
