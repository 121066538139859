import React from "react";
import moment from "moment";
import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/Badge";

export default function EventFeed(props) {
  const { handlers, events } = props;
  let reverse = props.reverse || false;
  const sortedEvents = reverse ? events.slice(0).reverse() : events;

  return (
    <div>
      <h5>WSS Broadcasts </h5>
      <div
        id="events"
        style={{
          height: "500px",
          overflowY: "scroll",
          fontSize: "12px",
          textAlign: "left"
        }}
      >
        <ul className="list-group">
          {sortedEvents.map((e, idx) => (
            <li key={"msg-" + idx} className="list-group-item">
              <Badge variant="secondary">{e.source}</Badge>
              <Button
                href={"#" + idx}
                variant="link"
                size="sm"
                onClick={handlers.showEventDetails}
              >
                {e.eventName}
              </Button>{" "}
              <p className="float-right">{moment(e.ts).fromNow()}</p>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
